import { useCallback, useEffect, useRef, useState } from "react";
import { NavLink, useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { onExperienceNextButtonClick } from "../analytics/analytics.click-handlers";
import { Spacers } from "../assets/constants/Spacers";
import { appLinks } from "../assets/constants/Strings";
import {
  bdayPalCaseStudy,
  bdayPalPromoScreens,
  calpolySrePoster,
} from "../assets/images";
import { RoutePath } from "../navigation/Routing";
import { ExperienceId, IExperienceContent } from "../types/IExperienceContent";
import { IProject } from "../types/IProject";
import { getExperienceContent, nextButtonTitle, previousButtonTitle } from "./Experience.constants";

const { pageContent } = Spacers;

const Experience = () => {
  let [searchParams] = useSearchParams();
  const experienceIdRef = useRef<string | undefined>();
  const currentExperienceId= experienceIdRef.current
  const navigate = useNavigate();
  const [experience, setExperience] = useState<
    IExperienceContent | undefined
  >();
  const previousButtonRoute =
    (experience &&
      `${RoutePath.Experience}?experienceId=${experience.prevId}`) ??
    "#";
  const nextButtonRoute =
    (experience &&
      `${RoutePath.Experience}?experienceId=${experience.nextId}`) ??
    "#";

  const onClickPrevious = useCallback(() => onExperienceNextButtonClick({
    href: previousButtonRoute,
    targetText: previousButtonTitle
  }), [previousButtonRoute]);
  
  const onClickNext = useCallback(() => onExperienceNextButtonClick({
    href: nextButtonRoute,
    targetText: nextButtonTitle
  }), [nextButtonRoute]);


  useEffect(() => {
    const experienceId = searchParams.get("experienceId");
    if (experienceId) {
      experienceIdRef.current = experienceId;
      const content = getExperienceContent(experienceIdRef.current);
      if (content) {
        setExperience(content);
      } else {
        navigate(RoutePath.Home);
      }
    }
  }, [experience, navigate, searchParams]);

  const projectListItems = experience?.projectList.map((project: IProject) => {
    return (
      <li key={`experience-entry-${project.title}`}>
        {project.title}
        <ul>
          {project.additionalDescription !== undefined && (
            <li>{project.additionalDescription}</li>
          )}
          {project.technologies !== undefined && (
            <li>{project.technologies}</li>
          )}
          {project.links !== undefined &&
            project.links?.map((link, index) => {
              return (
                <li  key={`project-link-entry-title-${link.linkTitle}`}>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    key={index}
                    href={link.href}
                  >
                    {link.linkTitle}
                  </a>
                </li>
              );
            })}
        </ul>
      </li>
    );
  });
  return (
    <PageContainer>
      <ContentContainerSection>
        <PageHeader>{experience?.role}</PageHeader>
        <PageSubHeader>{experience?.stackArea}</PageSubHeader>
        <CompanyTitle>{experience?.company}</CompanyTitle>
        <DescriptionTitle>Description</DescriptionTitle>
        <ul>{projectListItems}</ul>

        {currentExperienceId === ExperienceId.CalPoly && (
          <img src={calpolySrePoster} width="75%vp" height="auto" alt="Cal Poly Pomona SRE Poster"/>
        )}
        {currentExperienceId === ExperienceId.IosBdayPal && (
          <>
            <BdayPalSectionTitle>Release</BdayPalSectionTitle>
            <img src={bdayPalPromoScreens} width="50%vp" height="auto" alt='Birthday Pal Mobile App Hero Shots'/>
            <BdayPalSectionTitle>Process</BdayPalSectionTitle>
            <img src={bdayPalCaseStudy} width="90%vp" height="auto" alt='Birthday Pal Mobile App Case Study'/>
          </>
        )}
        {currentExperienceId === ExperienceId.UcdInfinityMirror && (
          <VideoContainer>
            <iframe
              title="Infinity Mirror YouTube Video"
              width="100%"
              height="100%"
              src={appLinks.youtubeInfinityMirror}
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            ></iframe>
          </VideoContainer>
        )}
        <BackAndNextExpContainer>
          <NavLink onClick={onClickPrevious} to={previousButtonRoute}>
            {previousButtonTitle}
          </NavLink>
          <Separator>{" | "}</Separator>
          <NavLink onClick={onClickNext} to={nextButtonRoute}>
            {nextButtonTitle}
          </NavLink>
        </BackAndNextExpContainer>
      </ContentContainerSection>
    </PageContainer>
  );
};

const PageContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const VideoContainer = styled.div`
  max-width: 560px;
  height: 315px;
`;

const ContentContainerSection = styled.section`
  max-width: ${pageContent.maxWidth.px};
  margin: 0 auto;
  padding: ${pageContent.padding.top.px} ${pageContent.padding.horizontal.px};
    ${pageContent.padding.bottom.px};
  overflow: auto;
  width: 100%;
`;

const BackAndNextExpContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 25px;
`;

const Separator = styled.p`
  padding: 0 5px;
`;

const PageHeader = styled.h2``;
const PageSubHeader = styled.h3``;
const CompanyTitle = styled.h4`
  margin-bottom: 25px;
`;
const DescriptionTitle = styled.p`
  margin-bottom: 10px;
`;
const BdayPalSectionTitle = styled.h2``;
export default Experience;
