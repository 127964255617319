import {
  aboutMe1,
  aboutMe2,
  aboutMe3,
  aboutMe4,
  aboutMe5,
  aboutMe6,
  aboutMe7,
  aboutMe8,
} from "../assets/images";

export const carouselGridContent = [
  {
    alt: 'Carousel portait number 1',
    img: aboutMe1,
    key: "aboutMe1",
  },
  {
    alt: 'Carousel portait number 2',
    img: aboutMe2,
    key: "aboutMe2",
  },
  {
    alt: 'Carousel portait number 3',
    img: aboutMe3,
    key: "aboutMe3",
  },
  {
    alt: 'Carousel portait number 4',
    img: aboutMe4,
    key: 'aboutMe4',
  },
  {
    alt: 'Carousel portait number 5',
    img: aboutMe5,
    key: "aboutMe5",
  },
  {
    alt: 'Carousel portait number 6',
    img: aboutMe6,
    key: "aboutMe6",
  },
  {
    alt: 'Carousel portait number 7',
    img: aboutMe7,
    key: "aboutMe7",
  },
  {
    alt: 'Carousel portait number 8',
    img: aboutMe8,
    key: "aboutMe8",
  },
];