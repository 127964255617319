export const Spacers = {
  pageContent: {
    maxWidth: {
      num: 950,
      px: "950px",
    },
    padding: {
      top: {
        num: 100,
        px: "100px",
      },
      horizontal: {
        num: 50,
        px: "50px",
      },
      bottom: {
        num: 25,
        px: "25px",
      },
    },
  },
};
