// Component Location
export enum ActionType {
  CUSTOM_GRID_CARD_LINK = "CUSTOM_GRID_CARD_LINK",
  FOOTER_LINK = "FOOTER_LINK",
  HEADER_LINK = "HEADER_LINK",
  HOME_CONNECT_LINK = "CONNECT_LINK",
  PREVIOUS_LINK = "PREVIOUS_LINK",
  NEXT_LINK = "NEXT_LINK",
}

// User Interest
export enum CategoryType {
  EXPERIENCE_NEXT_CLICK = "EXPERIENCE_NEXT_CLICK",
  EXPERIENCE_PREVIOUS_CLICK = "EXPERIENCE_PREVIOUS_CLICK",
  HOME_EXPERIENCE_CLICK = "HOME_EXPERIENCE_CLICK",
  NAVIGATION_CLICK = "NAVIGATION_CLICK",
  SOCIAL_MEDIA_CLICK = "SOCIAL_MEDIA_CLICK",
}

export interface IAnalyticsClickProps {
  action: ActionType;
  category: CategoryType;
  href: string;
  targetText: string;
}

export interface IBuildLinkClickHandlerProps extends IAnalyticsClickProps {
  scrollToTop?: boolean;
}


export interface IBasicClickProps {
  href: string;
  targetText: string;
}