export const footerMenuContainerFonts = `
  font-family: "Oswald", sans-serif;
  font-weight: 400;
`;

export const navMenuContainerFonts = `
  font-family: "Oswald", sans-serif;
  font-weight: 400;
`;

export const navLinksFontStyle = `
  font-size: 20px;
  font-weight: bold;
`;

export const navLinksNavNameFontStyle = `
  font-size: 25px;
`;