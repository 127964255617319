import ReactGA from "react-ga";
import { IExperienceCard, IExperienceChanged, ISocialLink } from "../types";
import { ActionType, CategoryType, IBasicClickProps, IBuildLinkClickHandlerProps } from "./analytics.types";

/**
 * 
 * This function is used to track user clicks on the website
 * with Google Analytics. IT Additionally allows for scrolling
 * to top of the page after the click event.
 *  
 */
export const buildLinkClickHandler = ({
  action,
  category,
  href,
  targetText,
  scrollToTop = true,
}: IBuildLinkClickHandlerProps) => {
  
  ReactGA.event({
    category: category,
    action: action,
    label: `${href}-${targetText}`,
  });

  if (scrollToTop) {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }, 10);
  }
};

/**
 * This function is a buildLinkClickHandler wrapper to be
 * used to track social media icon click in the Footer.
 */
export const onSocialMediaBarIconClick = (social: ISocialLink) => {
  const { href, type } = social;
  const targetText = `${type}`;
  return buildLinkClickHandler({
    action: ActionType.FOOTER_LINK,
    category: CategoryType.SOCIAL_MEDIA_CLICK,
    href,
    targetText,
    scrollToTop: false,
  })};

/**
 * This function is a buildLinkClickHandler wrapper to be
 * used to track social media icon clicks at Home Page > Connect.
 */
  export const onHomeConnectSMClick = (social: ISocialLink) => {
    const { href, type } = social;
    const targetText = `${type}`;
    return buildLinkClickHandler({
      action: ActionType.HOME_CONNECT_LINK,
      category: CategoryType.SOCIAL_MEDIA_CLICK,
      href,
      targetText,
      scrollToTop: false,
    });
  };

  /**
 * This function is a buildLinkClickHandler wrapper to be
 * used to track experience card clicks at Home Page > Experience.
 */
export const onHomeExperienceCardClick = (experienceCard: IExperienceCard) => {
  return buildLinkClickHandler({
    action: ActionType.CUSTOM_GRID_CARD_LINK,
    category: CategoryType.HOME_EXPERIENCE_CLICK,
    href: experienceCard.href,
    targetText: experienceCard.linkTitle,
  });
  };

export const onExperiencePreviousButtonClick = (expChanged: IExperienceChanged) => {
  return buildLinkClickHandler({
    action: ActionType.PREVIOUS_LINK,
    category: CategoryType.EXPERIENCE_PREVIOUS_CLICK,
    href: expChanged.href,
    targetText: expChanged.targetText,
  });
};


export const onExperienceNextButtonClick = (expChanged: IExperienceChanged) => {
  return buildLinkClickHandler({
    action: ActionType.NEXT_LINK,
    category: CategoryType.EXPERIENCE_NEXT_CLICK,
    href: expChanged.href,
    targetText: expChanged.targetText,
  });
};


export const onFooterMenuClick = (props: IBasicClickProps) => {
  return buildLinkClickHandler({
    action: ActionType.FOOTER_LINK,
    category: CategoryType.NAVIGATION_CLICK,
    href: props.href,
    targetText: props.targetText,
    scrollToTop: false
  });
}

export const onNavigationMenuClick = (props: IBasicClickProps) => {
  return buildLinkClickHandler({
    action: ActionType.HEADER_LINK,
    category: CategoryType.NAVIGATION_CLICK,
    href: props.href,
    targetText: props.targetText,
    scrollToTop: false
  })
};

export const onPolicyClick = (props: IBasicClickProps) => {
  return buildLinkClickHandler({
    action: ActionType.FOOTER_LINK,
    category: CategoryType.NAVIGATION_CLICK,
    href: props.href,
    targetText: props.targetText,
  })
};