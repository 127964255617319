import { Spacers } from "../assets/constants/Spacers";
import { privacyPolicyContent } from "../assets/constants/Strings";
import styled from "../styled-components";

const { pageContent } = Spacers;

const PrivacyPolicy = () => {
  return (
    <PageContainer>
      <PageContentContainer>
        <Title>{privacyPolicyContent.title}</Title>
        <h3>
          <i>Last updated January 23, 2024</i>
        </h3>
        <br />
        <p>
          Thank you for choosing to be part of our community at Andrea Lopez ("Company," "we," "us," or "our"). We are committed to protecting
          your personal information and your right to privacy. If you have any questions or concerns about this privacy notice or our
          practices with regard to your personal information, please contact us at emailandrealopez.com.
        </p>
        <br />
        <p>This privacy notice describes how we might use your information if you:</p>
        <ul>
          <li>
            <p>Visit our website at https://andrealopez.dev</p>
          </li>
          <li>
            <p>Engage with us in other related ways ― including any sales, marketing, or events</p>
          </li>
        </ul>
        <p>In this privacy notice, if we refer to:</p>
        <ul>
          <li>
            <p>"Website," we are referring to any website of ours that references or links to this policy</p>
          </li>
          <li>
            <p>"Services," we are referring to our Website, and other related services, including any sales, marketing, or events</p>
          </li>
        </ul>
        <p>
          The purpose of this privacy notice is to explain to you in the clearest way possible what information we collect, how we use it, and
          what rights you have in relation to it. If there are any terms in this privacy notice that you do not agree with, please discontinue
          use of our Services immediately.
        </p>
        <p>Please read this privacy notice carefully, as it will help you understand what we do with the information that we collect.</p>
        <br />

        <h2>What information do we collect</h2>
        <h3>
          <b>Personal information you disclose to us</b>
        </h3>
        <p>In Short: We collect personal information that you provide to us.</p>
        <br />
        <p>
          We collect personal information that you voluntarily provide to us when you express an interest in obtaining information about us or
          our products and Services, when you participate in activities on the Website or otherwise when you contact us.
        </p>
        <br />
        <p>
          The personal information that we collect depends on the context of your interactions with us and the Website, the choices you make
          and the products and features you use. The personal information we collect may include the following:
        </p>
        <br />
        <p>
          All personal information that you provide to us must be true, complete and accurate, and you must notify us of any changes to such
          personal information.
        </p>
        <br />

        <h3>
          <b>Information automatically collected</b>
        </h3>
        <p>
          In Short: Some information — such as your Internet Protocol (IP) address and/or browser and device characteristics — is collected
          automatically when you visit our Website.
        </p>
        <br />
        <p>
          We automatically collect certain information when you visit, use or navigate the Website. This information does not reveal your
          specific identity (like your name or contact information) but may include device and usage information, such as your IP address,
          browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location,
          information about how and when you use our Website and other technical information. This information is primarily needed to maintain
          the security and operation of our Website, and for our internal analytics and reporting purposes.
        </p>
        <br />
        <p>The information we collect includes:</p>
        <ul>
          <li>
            <p>
              Log and Usage Data. Log and usage data is service-related, diagnostic, usage and performance information our servers
              automatically collect when you access or use our Website and which we record in log files. Depending on how you interact with
              us, this log data may include your IP address, device information, browser type and settings and information about your activity
              in the Website (such as the date/time stamps associated with your usage, pages and files viewed, searches and other actions you
              take such as which features you use), device event information (such as system activity, error reports (sometimes called 'crash
              dumps') and hardware settings).
            </p>
          </li>
          <li>
            <p>
              Location Data. We collect location data such as information about your device's location, which can be either precise or
              imprecise. How much information we collect depends on the type and settings of the device you use to access the Website. For
              example, we may use GPS and other technologies to collect geolocation data that tells us your current location (based on your IP
              address). You can opt out of allowing us to collect this information either by refusing access to the information or by
              disabling your Location setting on your device. Note however, if you choose to opt out, you may not be able to use certain
              aspects of the Services.
            </p>
          </li>
        </ul>

        <h2>How do we use your information?</h2>
        <p>
          In Short: We process your information for purposes based on legitimate business interests, the fulfillment of our contract with you,
          compliance with our legal obligations, and/or your consent.
        </p>
        <br />
        <p>
          We use personal information collected via our Website for a variety of business purposes described below. We process your personal
          information for these purposes in reliance on our legitimate business interests, in order to enter into or perform a contract with
          you, with your consent, and/or for compliance with our legal obligations. We indicate the specific processing grounds we rely on
          next to each purpose listed below.
        </p>
        <br />
        <p>We use the information we collect or receive:</p>
        <ul>
          <li>
            <p>
              To deliver and facilitate delivery of services to the user. We may use your information to provide you with the requested
              service.
            </p>
          </li>
          <li>
            <p>
              To respond to user inquiries/offer support to users. We may use your information to respond to your inquiries and solve any
              potential issues you might have with the use of our Services.
            </p>
          </li>
          <li>
            <p>
              For other business purposes. We may use your information for other business purposes, such as data analysis, identifying usage
              trends, determining the effectiveness of our promotional campaigns and to evaluate and improve our Website, products, marketing
              and your experience. We may use and store this information in aggregated and anonymized form so that it is not associated with
              individual end users and does not include personal information
            </p>
          </li>
        </ul>

        <h2>Will your information be shared with anyone</h2>
        <p>
          In Short: We only share information with your consent, to comply with laws, to provide you with services, to protect your rights, or
          to fulfill business obligations.
        </p>
        <br />
        <p>We may process or share your data that we hold based on the following legal basis:</p>
        <ul>
          <li>
            <p>
              Consent: We may process your data if you have given us specific consent to use your personal information for a specific purpose.
            </p>
          </li>
          <li>
            <p>
              Legitimate Interests: We may process your data when it is reasonably necessary to achieve our legitimate business interests.
            </p>
          </li>
          <li>
            <p>
              Performance of a Contract: Where we have entered into a contract with you, we may process your personal information to fulfill
              the terms of our contract.
            </p>
          </li>
          <li>
            <p>
              Legal Obligations: We may disclose your information where we are legally required to do so in order to comply with applicable
              law, governmental requests, a judicial proceeding, court order, or legal process, such as in response to a court order or a
              subpoena (including in response to public authorities to meet national security or law enforcement requirements).
            </p>
          </li>
          <li>
            <p>
              Vital Interests: We may disclose your information where we believe it is necessary to investigate, prevent, or take action
              regarding potential violations of our policies, suspected fraud, situations involving potential threats to the safety of any
              person and illegal activities, or as evidence in litigation in which we are involved.
            </p>
          </li>
        </ul>
        <p>More specifically, we may need to process your data or share your personal information in the following situations:</p>
        <ul>
          <li>
            <p>
              Business Transfers. We may share or transfer your information in connection with, or during negotiations of, any merger, sale of
              company assets, financing, or acquisition of all or a portion of our business to another company.
            </p>
          </li>
          <li>
            <p>
              Vendors, Consultants and Other Third-Party Service Providers. We may share your data with third-party vendors, service
              providers, contractors or agents who perform services for us or on our behalf and require access to such information to do that
              work. Examples include: payment processing, data analysis, email delivery, hosting services, customer service and marketing
              efforts. We may allow selected third parties to use tracking technology on the Website, which will enable them to collect data
              on our behalf about how you interact with our Website over time. This information may be used to, among other things, analyze
              and track data, determine the popularity of certain content, pages or features, and better understand online activity. Unless
              described in this notice, we do not share, sell, rent or trade any of your information with third parties for their promotional
              purposes.
            </p>
          </li>
        </ul>
        <h2>Who will your information eb shared with?</h2>
        <p>In Short: We only share information with the following third parties.</p>
        <br />
        <p>
          We only share and disclose your information with the following third parties. If we have processed your data based on your consent
          and you wish to revoke your consent, please contact us using the contact details provided in the section below titled "How can you
          contact us about this notice?".
        </p>
        <ul>
          <li>
            <p>Web and Mobile Analytics: Google Analytics</p>
          </li>
        </ul>

        <h2>How long do we keep your information?</h2>
        <p>
          In Short: We keep your information for as long as necessary to fulfill the purposes outlined in this privacy notice unless otherwise
          required by law.
        </p>
        <br />
        <p>
          We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy notice, unless a
          longer retention period is required or permitted by law (such as tax, accounting or other legal requirements). No purpose in this
          notice will require us keeping your personal information for longer than 2 years.
        </p>
        <br />
        <p>
          When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize such
          information, or, if this is not possible (for example, because your personal information has been stored in backup archives), then
          we will securely store your personal information and isolate it from any further processing until deletion is possible.
        </p>
        <br />

        <h2>How do we keep your information safe?</h2>
        <p>In Short: We aim to protect your personal information through a system of organizational and technical security measures.</p>
        <br />
        <p>
          We have implemented appropriate technical and organizational security measures designed to protect the security of any personal
          information we process. However, despite our safeguards and efforts to secure your information, no electronic transmission over the
          Internet or information storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers,
          cybercriminals, or other unauthorized third parties will not be able to defeat our security, and improperly collect, access, steal,
          or modify your information. Although we will do our best to protect your personal information, transmission of personal information
          to and from our Website is at your own risk. You should only access the Website within a secure environment.
        </p>
        <br />

        <h2>What are your privacy rights?</h2>
        <p>In Short: You may review, change, or terminate your account at any time.</p>
        <br />
        <p>
          If you are a resident in the EEA or UK and you believe we are unlawfully processing your personal information, you also have the
          right to complain to your local data protection supervisory authority. You can find their contact details here:
          https://ec.europa.eu/justice/data- protection/bodies/authorities/index_en.htm.
        </p>
        <br />
        <p>
          If you are a resident in Switzerland, the contact details for the data protection authorities are available here:
          https://www.edoeb.admin.ch/edoeb/en/home.html.
        </p>
        <br />

        <h2>Controls for do-not-track features</h2>
        <p>
          Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ("DNT") feature or setting you
          can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At
          this stage no uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not
          currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked
          online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a
          revised version of this privacy notice.
        </p>
        <br />

        <h2>Do California residents have specific privacy</h2>
        <p>
          In Short: Yes, if you are a resident of California, you are granted specific rights regarding access to your personal information.
        </p>
        <br />
        <p>
          California Civil Code Section 1798.83, also known as the "Shine The Light" law, permits our users who are California residents to
          request and obtain from us, once a year and free of charge, information about categories of personal information (if any) we
          disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared
          personal information in the immediately preceding calendar year. If you are a California resident and would like to make such a
          request, please submit your request in writing to us using the contact information provided below.
        </p>
        <br />

        <h2>Do we make updates to this notice</h2>
        <p>In Short: Yes, we will update this notice as necessary to stay compliant with relevant laws.</p>
        <br />
        <p>
          We may update this privacy notice from time to time. The updated version will be indicated by an updated "Revised" date and the
          updated version will be effective as soon as it is accessible. If we make material changes to this privacy notice, we may notify you
          either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this
          privacy notice frequently to be informed of how we are protecting your information.
        </p>
        <br />

        <h2>How can you contact us about this notice?</h2>
        <p>If you have questions or comments about this notice, you may email us at emailandrealopez.com</p>
        <br />

        <h2>How can you review update or delete the data we collect</h2>
        <p>
          Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from
          you, change that information, or delete it in some circumstances. To request to review, update, or delete your personal information,
          please submit a request form by clicking
          <a href="https://app.termly.io/notify/4ab39c5f-e46f-40b5-82d9-a753569d561b" target="_blank" rel={'noreferrer'}>
            <p>here</p>
          </a>
          .
        </p>
      </PageContentContainer>
    </PageContainer>
  );
};

const PageContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const Title = styled.h1`
  padding-bottom: 25px;
`;

const PageContentContainer = styled.div`
  max-width: ${pageContent.maxWidth.px};
  margin: 0 auto;
  padding: ${pageContent.padding.top.px} ${pageContent.padding.horizontal.px} ${pageContent.padding.bottom.px};
  overflow: auto;
`;

export default PrivacyPolicy;