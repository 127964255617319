import { useEffect } from "react";
import ReactGA from "react-ga";
import { useLocation } from "react-router-dom";

/**
 * Hook to track page views.
 */
export const useAnalyticsPageViews = () => {
  const location = useLocation();
  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);
}