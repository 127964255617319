import { appLinks } from "../assets/constants/Strings";
import { Dictionary, ExperienceId, IExperienceContent } from "../types";

export const previousButtonTitle = "Previous";
export const nextButtonTitle = "Next";
export const experienceContent = {
  [ExperienceId.HboMax]: {
    role: "Software Engineer, Lead",
    stackArea: "Frontend Web and Mobile Applications",
    company: "Warner Brothers Discovery",
    projectList: [
      {
        title: "Max | Streaming Entertainment Web Application",
        technologies:
          "Technologies used: React, JavaScript, Typescript, Storybook",
        links: [{ href: appLinks.maxWebsite, linkTitle: "Max Site" }],
      },
      {
        title:
          "HBO Max | Streaming Entertainment Web and Mobile (iOS/android) Applications",
        technologies: "Technologies used: React Native, JavaScript, Typescript",
      },
    ],
    prevId: ExperienceId.CitrusHighAltitudeBalloon,
    nextId: ExperienceId.Isbx,
  },
  [ExperienceId.Isbx]: {
    role: "Software Developer",
    stackArea: "Full Stack Mobile and Web Applications",
    company: "ISBX Corporation",
    projectList: [
      {
        title: "RedBull CoPilot | Internal Event Management Mobile Application",
        technologies:
          "Technologies used: React, React Native, JavaScript, Typescript, Redux, Node.js, Express, MongoDB, Firebase",
        links: [
          {
            href: appLinks.isbxRedbullCaseStudy,
            linkTitle: "Company listing",
          },
        ],
        additionalDescription: "Mobile app redesign rebuild",
      },
      {
        title: "Puffco | Bluetooth Vaporizer Mobile Application",
        technologies:
          "Technologies used: React Native, Typescript, NestJS, typeORM",
        links: [
          {
            href: appLinks.puffcoYoutubePromo,
            linkTitle: "Youtube promo",
          },
          {
            href: appLinks.puffcoIsbxCaseStudy,
            linkTitle: "Company listing",
          },
        ],
        additionalDescription: "Mobile app launch and maintenance",
      },
      {
        title: "Rapid Credit | Credit Management Mobile Application",
        technologies: "Technologies used: Expo, React Native, Typescript",
        links: [
          {
            href: appLinks.rapidCreditAppstore,
            linkTitle: "App store listing",
          },
        ],
        additionalDescription: "Mobile app redesign rebuild",
      },
      {
        title: "HED Unity | Bluetooth Headphone Mobile Application",
        technologies: "Technologies used: Swift, RxSwift",
        additionalDescription:
          "Mobile app redesign rebuild, API integration with well-known music streaming providers",
      },
    ],
    prevId: ExperienceId.HboMax,
    nextId: ExperienceId.Intel,
  },
  [ExperienceId.Intel]: {
    role: "Graduate Software Engineer Intern",
    stackArea: "Frontend Web Applications",
    company: "Intel Corporation",
    projectList: [
      {
        title:
          "Created a proof of concept software tool that checked if servers, databases, APIs, inter-process communication framework tools, websites, and windows applications were fully functional after a monthly system patch, and reboot using C# and .NET Framework",
      },
      {
        title:
          "Researched, developed, and implemented methods for optimally displaying product manufacturing flow charts on internal websites to be adopted by 60+ users using Wiki Enterprise, HTML, and CSS",
      },
      {
        title:
          "Researched methods for inserting data tables with dynamic attributes into databases using SQL Server Integration Services and SQL Server Management Services",
      },
    ],
    prevId: ExperienceId.Isbx,
    nextId: ExperienceId.CalPoly,
  },
  [ExperienceId.CalPoly]: {
    role: "Embedded Engineer Intern",
    stackArea: "Embedded Systems",
    company: "Cal Poly Pomona CS Dept.",
    projectList: [
      {
        title:
          "Created a smart pet door prototype that was a cost-effective substitution to existing pet doors, with mobile and intelligent capabilities.",
        technologies:
          "Technologies used: Raspberry Pi 2, Arduino, Bluetooth beacon, linear servo motor",
      },
    ],
    prevId: ExperienceId.Intel,
    nextId: ExperienceId.IosBdayPal,
  },
  [ExperienceId.IosBdayPal]: {
    role: "iOS Engineer",
    stackArea: "Mobile Applications",
    company: "Andrea Lopez",
    projectList: [
      {
        title:
          "Created an iOS birthday mobile application that lets you organize your birthdays by friends and groups.",
        additionalDescription:
          "Features: import from contacts, notifications, customizable theme colors, and CoreData solution in",
        technologies:
          "Technologies used: Swift, CoreData, AppStoreConnect, Testflight, Sketch ",
        links: [
          {
            href: appLinks.bdayPalPromoWebsite,
            linkTitle: "BdayPal promo website",
          },
          {
            href: appLinks.bdayPalAppstore,
            linkTitle: "Appstore listing",
          },
        ],
      },
    ],
    prevId: ExperienceId.CalPoly,
    nextId: ExperienceId.WebStyledResume,
  },
  [ExperienceId.WebStyledResume]: {
    role: "Web Engineer",
    stackArea: "Web Applications",
    company: "Andrea Lopez",
    projectList: [
      {
        title: "Web Styled Resume",
        technologies: "Technologies used: Jekyll, JS, CSS, HTML",
        links: [
          {
            href: appLinks.styledResumeAndreaLopezDev,
            linkTitle: "styledresume",
          },
        ],
      },
      {
        title: "PDF Resume",
        links: [
          { href: appLinks.resumeAndreaLopezDev, linkTitle: "resume" },
        ],
      },
    ],
    prevId: ExperienceId.IosBdayPal,
    nextId: ExperienceId.FpgaDigitImageRecognition,
  },
  [ExperienceId.FpgaDigitImageRecognition]: {
    role: "Embedded Engineer Student",
    stackArea: "Embedded Applications",
    company: "UCDavis Student Design Team",
    projectList: [
      {
        title:
          "Created an application specific processor that could recognize and classify handwritten digits using a field programmable gate array (FPGA).",
        technologies:
          "Technologies used: Cyclone V Altera FPGA, Quartus II, Github, MATLAB, C, Verilog",
        links: [
          {
            href: appLinks.fpgaDigitImageRecognitionWordpress,
            linkTitle: "Team project Wordpress blog",
          },
        ],
      },
    ],
    prevId: ExperienceId.WebStyledResume,
    nextId: ExperienceId.UcdInfinityMirror,
  },
  [ExperienceId.UcdInfinityMirror]: {
    role: "Embedded Engineer Student",
    stackArea: "Technologies used: Embedded Applications",
    company: "UC Davis EEEmerge Team",
    projectList: [
      {
        title:
          "Created a 2 ft. x 2 ft. infinity mirror, which was activated by hand proximity sensors.",
        technologies: "TI micro-controller, Code Composer Studio, C/C++.",
      },
    ],
    prevId: ExperienceId.FpgaDigitImageRecognition,
    nextId: ExperienceId.CitrusHighAltitudeBalloon,
  },
  [ExperienceId.CitrusHighAltitudeBalloon]: {
    role: "Embedded Engineer Student",
    stackArea: "Technologies used: Embedded Applications",
    company: "Citus College High Altitude Balloon Team",
    projectList: [
      {
        title:
          "Researched and conducted experiments relating to the cosmos and atmosphere.",
        technologies: "Arduino, Altimeter, Geiger counter, Recorder.",
        links: [
          {
            href: appLinks.arduinoCitrusHABFacebook,
            linkTitle: "High Altitude Balloon Team Facebook",
          },
        ],
      },
    ],
    prevId: ExperienceId.UcdInfinityMirror,
    nextId: ExperienceId.HboMax,
  },
} as Dictionary<ExperienceId, IExperienceContent>;

export const getExperienceContent = (experienceId: string) => {
  switch (experienceId) {
    case ExperienceId.HboMax:
      return experienceContent[ExperienceId.HboMax];
    case ExperienceId.Isbx:
      return experienceContent[ExperienceId.Isbx];
    case ExperienceId.Intel:
      return experienceContent[ExperienceId.Intel];
    case ExperienceId.CalPoly:
      return experienceContent[ExperienceId.CalPoly];
    case ExperienceId.IosBdayPal:
      return experienceContent[ExperienceId.IosBdayPal];
    case ExperienceId.WebStyledResume:
      return experienceContent[ExperienceId.WebStyledResume];
    case ExperienceId.FpgaDigitImageRecognition:
      return experienceContent[ExperienceId.FpgaDigitImageRecognition];
    case ExperienceId.UcdInfinityMirror:
      return experienceContent[ExperienceId.UcdInfinityMirror];
    case ExperienceId.CitrusHighAltitudeBalloon:
      return experienceContent[ExperienceId.CitrusHighAltitudeBalloon];
    default:
      return undefined;
  }
};