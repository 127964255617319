import { appLinks } from "../assets/constants/Strings";
import {
  githubLogo,
  instaLogo,
  linkedinLogo,
  snapChatLogo,
  xLogo,
  youtubeLogo,
} from "../assets/images";
import { Dictionary, ISocialLink, Social } from "../types";
export const socialLinksDict = {
  [Social.github]: { 
    href: appLinks.github, 
    type: Social.github, 
    img: githubLogo 
  },
  [Social.instagram]: { 
    href: appLinks.instagram,
    type: Social.instagram,
    img: instaLogo
  },
  [Social.snapchat]: { 
    href: appLinks.instagram,
    type: Social.snapchat,
    img: snapChatLogo
  },
  [Social.linkedin]: {
    href: appLinks.linkedin,
    type: Social.linkedin,
    img: linkedinLogo,
  },
  [Social.x]: {
    href: appLinks.x,
    type: Social.x,
    img: xLogo
  },
  [Social.youtube]: {
    href: appLinks.youtube,
    type: Social.youtube,
    img: youtubeLogo
  },
} as Dictionary<Social, ISocialLink>;