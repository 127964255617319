const basicColors = {
  black: "#000000",
  white: "#FFFFFF",
};

const brandColors = {
  red: "#C32D2D",
  navyBlue: "#15293C",
  charcoal: "#292929",

  //Used for cards, nav menu, and footer menu
  surfaceForeground: "#f6f8fa",
  surfaceForegroundText: "#34343c",
  surfaceForegroundBorder: "#efefef",
  
  surfaceForegroundNavigationLink: "#34343c",
  surfaceForegroundNavigationLinksHover: basicColors.black,
};

export const Colors = {
  ...brandColors,
  ...basicColors,
};

export default Colors;
