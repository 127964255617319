import { useCallback } from "react";
import { NavLink } from "react-router-dom";
import { onFooterMenuClick, onPolicyClick } from "../analytics/analytics.click-handlers";
import Colors from "../assets/constants/Colors";
import { footerMenuContainerFonts } from "../assets/constants/Fonts";
import { menuContainerLinkQueryMaxWidth, menuContainerQueryMaxWidth, menuContainerQueryMinWidth } from "../assets/constants/Queries";
import {
  connectTitleId,
  contactTitleId,
  experienceTitleId,
  footerContent,
} from "../assets/constants/Strings";
import { RoutePath } from "../navigation/Routing";
import styled from "../styled-components";
import SocialMediaBar from "./SocialMediaBar";

const Footer = () => {
  const homeRoute = RoutePath.Home

  const onMenuItemClick = useCallback((title: string, id: string) => (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    const route = `${homeRoute}#${id}`;
    onFooterMenuClick({href:route, targetText: title})
    setTimeout(() => {
      window.document
        .getElementById(id)
        ?.scrollIntoView({ behavior: "smooth" });
    }, 10);
  },[homeRoute]);;

  const onLinkClick = useCallback((href: string, targetText: string) => (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    onPolicyClick({href, targetText})
  },[]);;

  return (
    <FooterContainer id={contactTitleId}>
      <ContentContainer>
        <MenuContainer>
          <MenuLabel>{footerContent.menuTitle}</MenuLabel>
          <MenuLink
            onClick={onMenuItemClick(footerContent.menuTitle, experienceTitleId)}
            to={homeRoute}
          >
            {footerContent.menuItemExperienceTitle}
          </MenuLink>
          <MenuLink
            onClick={onMenuItemClick(footerContent.menuItemExperienceTitle, connectTitleId)}
            to={homeRoute}
          >
            {footerContent.menuItemConnectTitle}
          </MenuLink>
        </MenuContainer>
        <FarwellLabel>{footerContent.farwellMessage}</FarwellLabel>
        <SocialMediaBar />
        <BodyText>{footerContent.copyrightMessage}</BodyText>
        <PoliciesContainer>
          Policies:
          <StyledPolicyLink onClick={onLinkClick(RoutePath.PrivacyPolicy, footerContent.privacyPolicyTitle)} to={RoutePath.PrivacyPolicy}>{footerContent.privacyPolicyTitle}</StyledPolicyLink>
          <Separator>{" | "}</Separator>
          <StyledDisclaimerLink onClick={onLinkClick(RoutePath.Disclaimer, footerContent.disclaimerTitle)} to={RoutePath.Disclaimer}>{footerContent.disclaimerTitle}</StyledDisclaimerLink>
        </PoliciesContainer>
      </ContentContainer>
    </FooterContainer>
  );
};

const FooterContainer = styled.footer`
  background-color: ${Colors.surfaceForeground};
  border-top: 1px solid ${Colors.surfaceForegroundBorder};
  text-align: center;
    :hover {
      color: ${Colors.surfaceForegroundNavigationLinksHover};
    }
`;

const ContentContainer = styled.div`
  margin: 0 auto;
  max-width: 950px;
  overflow: auto;
  padding: 60px 50px;
`;

const MenuContainer = styled.div`
  ${footerMenuContainerFonts}
  
  @media (min-width: ${menuContainerQueryMinWidth}) {
      display: none;
  }

  @media (max-width: ${menuContainerQueryMaxWidth}) {
      text-align: left;
  }
`;

const MenuLabel = styled.h2`
  margin-bottom: 10px;
`;

const MenuLink = styled(NavLink)`
  color: ${Colors.surfaceForegroundText};
  font-size: 20px;
  text-decoration: none;
  text-transform: uppercase;
  
  @media (max-width: ${menuContainerLinkQueryMaxWidth}) {
    display: block;
}
`;

const FarwellLabel = styled.h2`
  margin-bottom: 10px;
`;

const BodyText = styled.p``;

const StyledPolicyLink = styled(NavLink)`
  font-size: 10px;
  padding-left: 5px;
`;

const StyledDisclaimerLink = styled(NavLink)`
  font-size: 10px;
`;

const Separator = styled.p`
  padding: 0 4px;
`;

const PoliciesContainer = styled.p`
  font-size: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
`;


export default Footer;
