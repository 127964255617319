import { useCallback } from "react";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { NavLink } from "react-router-dom";
import { onHomeExperienceCardClick } from "../analytics/analytics.click-handlers";
import Colors from "../assets/constants/Colors";
import { experiences } from "../pages/Home.constants";
import styled from "../styled-components";
import { IExperienceCard } from "../types";

const CustomGrid = () => {

  const onCardClick = useCallback((expCard: IExperienceCard) => (_: React.MouseEvent<HTMLAnchorElement, MouseEvent> | React.MouseEvent<HTMLElement, MouseEvent> | undefined) => onHomeExperienceCardClick(expCard), [])

  const cards = experiences.map((exp, index) => {
    const { linkTitle, href, image } = exp;
    return (
      <Col style={colStyle} key={index} onClick={onCardClick(exp)}>
        <Card key={`card-key-${linkTitle}`} style={cardStyle}>
          <NavLink style={cardLinkStyle} to={href}>
            <Card.Img variant="top" src={image} />
            {linkTitle}
          </NavLink>
        </Card>
      </Col>
    );
  });

  return (
    <GridContainer>
      <Row xs={2} sm={2} md={3} lg={4} className="g-4">
        {cards}
      </Row>
    </GridContainer>
  );
};

const colStyle = {
  cursor: "pointer",
};

const cardStyle = {
  backgroundColor: Colors.surfaceForeground,
  borderColor: Colors.surfaceForegroundBorder,
  justifyContent: "center",
  alignItems: "center",
  alignContent: "center",
};

const cardLinkStyle = {
  display: "contents",
  justifyContent: "center",
  alignItems: "center",
  alignContent: "center",
  alignSelf: "center",
};

const GridContainer = styled.div``;

export default CustomGrid;
