import { useEffect } from "react";
import ReactGA from "react-ga";
import { BrowserRouter } from "react-router-dom";
import Footer from "./components/Footer";
import NavigationBar from "./components/NavigationBar";
import Router from "./components/Router";

function App() {
  useEffect(() => {
    ReactGA.initialize("G-60WJ7007GZ");
  }, []);

  return (
    <BrowserRouter>
      <NavigationBar />
      <Router />
      <Footer />
    </BrowserRouter>
  );
}

export default App;
