import { useCallback } from "react";
import styled from "../styled-components";

import { onSocialMediaBarIconClick } from "../analytics/analytics.click-handlers";
import { ISocialLink } from "../types";
import { socialLinksDict } from "./SocialMediaBar.constants";

const SocialMediaBar = () => {

  const onSocialClick = useCallback((socialEntry: ISocialLink) => (_: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    onSocialMediaBarIconClick(socialEntry);
  }, []);

  const socialLinkComponents = Object.values(socialLinksDict).map((socialEntry, _) => {
    if (!socialEntry) {
      return null;
    }

    return (
      <SocialAnchor
        href={socialEntry.href}
        key={socialEntry.type + "_anchor"}
        rel={"external"}
        onClick={onSocialClick(socialEntry)}
        target={"_blank"}
      >
        <SocialImg
          src={socialEntry.img}
          key={socialEntry.type + "_img"}
          alt="test"
        />
      </SocialAnchor>
    );
  });

  return (
    <SocialMediaContainer>
      {socialLinkComponents}
    </SocialMediaContainer>
  );
};

const SocialMediaContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const SocialAnchor = styled.a`
  display: flex;
  border-radius: 100px;
  margin-left: 3px;
  margin-right: 3px;
  justify-content: center;
`;
const SocialImg = styled.img`
  width: 35px;
  height: 35px;
  padding: 10px;
  object-fit: contain;
`;

export default SocialMediaBar;
