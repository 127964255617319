/**
 * Links used everywhere in the application
 * */ 
export const appLinks = {
  // Social Media
  github: "https://www.github.com/andrearaylopez",
  instagram: "https://www.instagram.com/andrearaylopez/",
  snapchat: "https://www.snapchat.com/add/andrearaylopez",
  linkedin: "https://www.linkedin.com/in/andrearaylopez/",
  x: "https://x.com/andrearaylopez",
  youtube: "https://www.youtube.com/@andrearaylopez",
  //Ecperience links
  arduinoCitrusHABFacebook: "https://www.facebook.com/CCNearSpaceBalloon/",
  bdayPalAppstore: "https://apps.apple.com/us/app/bdaypal/id1462036900",
  bdayPalPromoWebsite: "https://bdaypal.andrealopez.dev/",
  fpgaDigitImageRecognitionWordpress: "https://thedirtybits181.wordpress.com/",
  isbxRedbullCaseStudy: "https://www.isbx.com/work/red-bull",
  maxWebsite: "https://play.max.com/",
  puffcoIsbxCaseStudy: "https://www.isbx.com/work/puffco",
  puffcoYoutubePromo: "https://www.youtube.com/watch?v=t3EK675D0cY&t=294",
  rapidCreditAppstore: "https://apps.apple.com/us/app/rapid-credit-credit-repair/id1476511090",
  resumeAndreaLopezDev: "https://resume.andrealopez.dev",
  styledResumeAndreaLopezDev: "https://styledresume.andrealopez.dev",
  youtubeInfinityMirror: "https://www.youtube.com/embed/ZGa1Ysp213g"

};

/*
  Navigation ids for scrolling
*/
export const connectTitleId = `connect`;
export const contactTitleId = `contact`;
export const experienceTitleId = `experience`;

/*
  Header content strings
*/
export const headerContent = {
  menuTitle: "Menu",
  menuItemConnectTitle: "Connect",
  menuItemExperienceTitle: "Experience",
  menuNameTitle: "Andrea Lopez | Dev",
};

/*
  Footer content strings
*/
export const footerContent = {
  copyrightMessage: "© 2024 Andrea Lopez",
  farwellMessage: "Keep in Touch",
  menuItemConnectTitle: "Connect",
  menuItemExperienceTitle: "Experience",
  menuTitle: "Menu",
  privacyPolicyTitle: "Privacy Policy",
  disclaimerTitle: "Disclaimer",
};

export const disclaimerContent = {
  title: "Disclaimer",
  body: 'The information provided by andreaLopez.dev (“we,” “us”, or “our”) on www.andrealopez.dev (the “Site”) is for general informational purposes only. All information on the Site is provided in good faith, however we make no representation or warranty of any kind, express or implied, regarding the accuracy, adequacy, validity, reliability, availability or completeness of any information on the Site. Under no circumstance shall we have any liability to you for any kind incurred as a result of the use of the site and your reliance on any information of the Site is solely at your own risk.'
};

export const privacyPolicyContent = {
  title: "Privacy Policy",
}

