import { Spacers } from "../assets/constants/Spacers";
import { disclaimerContent } from "../assets/constants/Strings";
import styled from "../styled-components";

const { pageContent } = Spacers;

const Disclaimer = () => {
  var disclaimerParts = disclaimerContent.body.split('. ');
  const textComponetnts = disclaimerParts?.map((string: string, i: number) => {
    return (
      <div key={'disclaimaerpart-' + i}>
        <p>{string}.</p>
        <br />
      </div>
    );
  })
  return (
    <PageContainer>
      <PageContentContainer>
        <Title>{disclaimerContent.title}</Title>
        {textComponetnts}
      </PageContentContainer>
    </PageContainer>
  );
};

const PageContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const PageContentContainer = styled.div`
  max-width: ${pageContent.maxWidth.px};
  margin: 0 auto;
  padding: ${pageContent.padding.top.px} ${pageContent.padding.horizontal.px} ${pageContent.padding.bottom.px};
  overflow: auto;
`;

const Title = styled.h1`
  padding-bottom: 25px;
`;
export default Disclaimer;