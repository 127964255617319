import Carousel from "react-bootstrap/Carousel";
import styled from "styled-components";
import { carouselGridContent } from "./CustomCarousel.constants";

const CustomCarousel = () => {

  const carouselItems = carouselGridContent.map(({alt, img, key}, _) => {
    return (
      <Carousel.Item key={key}>
        <CarouselImg src={img} alt={alt} />
      </Carousel.Item>
    );
  });

  return (
    <CarouselContainer>
      <ContentContainer>
        <Carousel>{carouselItems}</Carousel>
      </ContentContainer>
    </CarouselContainer>
  );
};
const CarouselContainer = styled.div`
  padding: 20px 0;
`;

const ContentContainer = styled.div`
  width: 325px;
`;

const CarouselImg = styled.img`
  width: 325px;
`;
export default CustomCarousel;
