import { useCallback } from "react";
import Card from "react-bootstrap/esm/Card";
import { onHomeConnectSMClick } from "../analytics/analytics.click-handlers";
import Colors from "../assets/constants/Colors";
import { Spacers } from "../assets/constants/Spacers";
import {
  appLinks,
  connectTitleId,
  experienceTitleId,
} from "../assets/constants/Strings";
import { linkedinLogo, xLogo } from "../assets/images";
import Carousel from "../components/CustomCarousel";
import CustomGrid from "../components/CustomGrid";
import styled from "../styled-components";
import { Social } from "../types";
import { homeContent } from "./Home.constants";

const { pageContent } = Spacers;

const Home = () => {

  const xClicked = useCallback(() => onHomeConnectSMClick({
    href: appLinks.x,
    type: Social.x,
    img: xLogo
  }), [])

  const linkedinClicked = useCallback(() => onHomeConnectSMClick({
    href: appLinks.linkedin,
    type: Social.linkedin,
    img: linkedinLogo,
  }),[])

  return (
    <PageContainer>
      <PageContentContainer>
        <SectionTitleText>{homeContent.aboutMeTitle}</SectionTitleText>
        <PageSectionContainer>
          <Card style={cardStyles}>
            <Carousel />
          </Card>
          {homeContent.aboutMeContent.map((content, index) => (
            <BodyText key={`about-me-${index}`}>{content}</BodyText>
          ))}
        </PageSectionContainer>

        <SectionTitleText id={experienceTitleId}>{homeContent.experienceTitle}</SectionTitleText>
        <PageSectionContainer>
          <CustomGrid />
        </PageSectionContainer>

        <SectionTitleText id={connectTitleId}>
          {homeContent.connectTitle}
        </SectionTitleText>
        <PageSectionContainer>
          <BodyText>
            {homeContent.connect_line1_0}
            <a onClick={linkedinClicked} href={appLinks.linkedin} target="_blank" rel="noreferrer">
              {homeContent.connect_line1_1} <ConnectLinkIcon src={linkedinLogo} />
            </a>
            {homeContent.connect_line1_2}
            <a onClick={xClicked} href={appLinks.x} target="_blank" rel="noreferrer">
              {homeContent.connect_line1_3}
              <ConnectLinkIcon src={xLogo} />
            </a>
            .
          </BodyText>
          <BodyText>{homeContent.connect_line2}</BodyText>
        </PageSectionContainer>
      </PageContentContainer>
    </PageContainer>
  );
};
export default Home;

export const cardStyles = {
  alignItems: "center",
  backgroundColor: Colors.surfaceForeground,
  borderColor: Colors.surfaceForegroundBorder,
  marginBottom: "15px",
};

const PageContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const PageContentContainer = styled.div`
  max-width: ${pageContent.maxWidth.px};
  margin: 0 auto;
  padding: ${pageContent.padding.top.px} ${pageContent.padding.horizontal.px} ${pageContent.padding.bottom.px};
  overflow: auto;
`;

const PageSectionContainer = styled.section`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 25px;
`;

const ConnectLinkIcon = styled.img`
  width: 15px;
  height: 15px;
  padding: 2px;
`;

const SectionTitleText = styled.h2``;

const BodyText = styled.p``;
