import { useCallback } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { onNavigationMenuClick } from "../analytics/analytics.click-handlers";
import Colors from "../assets/constants/Colors";
import { navLinksFontStyle, navLinksNavNameFontStyle, navMenuContainerFonts } from "../assets/constants/Fonts";
import { navMenuLinkQueryMaxWidth, navMenuLinkQueryMinWidth, navmenuItemLinkQueryMaxWidth, navmenuNameLinkQueryMaxWidth } from "../assets/constants/Queries";
import {
  appLinks,
  connectTitleId,
  contactTitleId,
  experienceTitleId,
  headerContent,
} from "../assets/constants/Strings";
import { githubLogo } from "../assets/images";
import { RoutePath } from "../navigation/Routing";

const NavigationBar = () => {
  
  const homeRoute = RoutePath.Home;
  const onMenuItemClick = useCallback((title: string, id?: string) => (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    const route = `${homeRoute}#${id}`;
    onNavigationMenuClick({href: route, targetText: title})
    if (id) {
      setTimeout(() => {
        window.document
          .getElementById(id)
          ?.scrollIntoView({ behavior: "smooth" });
      }, 10);
    } else {
      setTimeout(() => {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }, 10);
    }
  },[homeRoute]);;

  return (
    <Header>
      <Nav>
        <StyledNavLinkNavMenu
          onClick={onMenuItemClick(headerContent.menuTitle, contactTitleId)}
          to={homeRoute}
        >
          {headerContent.menuTitle}
        </StyledNavLinkNavMenu>
        <StyledNavLinkNavName
          onClick={onMenuItemClick(headerContent.menuNameTitle)}
          to={homeRoute}
        >
          {headerContent.menuNameTitle}
        </StyledNavLinkNavName>
        <StyledNavLinkNavMenuItem
          onClick={onMenuItemClick(headerContent.menuItemExperienceTitle, experienceTitleId)}
          to={homeRoute}
        >
          {headerContent.menuItemExperienceTitle}
        </StyledNavLinkNavMenuItem>
        <StyledNavLinkNavMenuItem
          onClick={onMenuItemClick(headerContent.menuItemConnectTitle, connectTitleId)}
          to={homeRoute}
        >
          {headerContent.menuItemConnectTitle}
        </StyledNavLinkNavMenuItem>
        <GitHubLinkMenuItem 
          href={appLinks.github}
          target="_blank"
          rel="noreferrer"
        >
          <Icon src={githubLogo} />
        </GitHubLinkMenuItem>
      </Nav>
    </Header>
  );
};

const Header = styled.header`
  background-color: ${Colors.surfaceForeground};
  z-index: 100;
`;

const Nav = styled.nav`
  background-color: ${Colors.surfaceForeground};
  border-bottom: 1px solid ${Colors.surfaceForegroundBorder};
  box-shadow: 0 2px 4px 0 rgba(0,0,0,.2);
  position: fixed;
  text-align: center;
  top: 0;
  width: 100%;
  z-index: 99;
  :hover {
    color:  ${Colors.surfaceForegroundNavigationLinksHover};
  }
  ${navMenuContainerFonts}
`;

const navLinkStyles = `
  ${navLinksFontStyle}
  color: ${Colors.surfaceForegroundNavigationLink};
  display: inline-block;
  padding: 14px 15px 20px 15px;
  text-decoration: none;
  text-transform: uppercase;
`;

const StyledNavLinkNavMenu = styled(NavLink)`
  ${navLinkStyles}
  padding-top: 18px;
  
  @media (min-width: ${navMenuLinkQueryMinWidth}) {
    display: none;
  }

  @media (max-width: ${navMenuLinkQueryMaxWidth}) {
      float: left;
  }

`;

const StyledNavLinkNavName = styled(NavLink)`
  ${navLinkStyles}
  ${navLinksNavNameFontStyle}
  padding-right: 22px;

  @media (max-width: ${navmenuNameLinkQueryMaxWidth}) {
    float: left;
  }
`;

const menuItemStyle = `
  ${navLinkStyles}

  @media (max-width: ${navmenuItemLinkQueryMaxWidth}) {
    display: none;
  }
`;

const StyledNavLinkNavMenuItem = styled(NavLink)`
  ${menuItemStyle}
`;

const GitHubLinkMenuItem = styled.a`
  ${menuItemStyle}
`;

const Icon = styled.img`
  width: 20px;
  height: 20px;
  margin-bottom: 5px;
`;

export default NavigationBar;
