export enum Social {
  github,
  instagram,
  snapchat,
  linkedin,
  x,
  youtube,
}

export interface ISocialLink {
  href: string;
  type: Social;
  img: string;
}
