import { IProject } from "./IProject";

export enum ExperienceId {
  HboMax = "hbo-max",
  Isbx = "isbx",
  Intel = "intel",
  CalPoly = "cal-poly",
  IosBdayPal = "ios-bday-pal",
  WebStyledResume = "web-styled-resume",
  FpgaDigitImageRecognition = "fpga-digit-image-recognition",
  UcdInfinityMirror = "ucd-infinity-mirror",
  CitrusHighAltitudeBalloon = "citrus-high-altitude-balloon",
}

export interface IExperienceContent {
  company: string;
  nextId?: ExperienceId;
  prevId?: ExperienceId;
  projectList: IProject[];
  role: string;
  stackArea: string;
}
