import Colors from "../assets/constants/Colors";
import { Spacers } from "../assets/constants/Spacers";
import styled, { css } from "../styled-components";

const { pageContent } = Spacers;

const NotFound = () => {
  return (
    <PageContainer>
      <CenteredContainer>
        <Circle>
          <InnerCircle>
            <NotFoundNum>{404}</NotFoundNum>
          </InnerCircle>
        </Circle>
        <NotFoundText>{'whoops... page not found'}</NotFoundText>
      </CenteredContainer>
    </PageContainer>
  );
}

const PageContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const CenteredContainer = styled.div`
  max-width: ${pageContent.maxWidth.px};
  margin: 0 auto;
  padding: ${pageContent.padding.top.px} ${pageContent.padding.horizontal.px} ${pageContent.padding.bottom.px};
  overflow: auto;
  min-height: 60vh;
`;
const Circle = styled.div`
  ${() => css`
    display: flex;
    border-width: 5rem;
    border: 5rem;
    width: 15rem;
    height: 15rem;
    align-items: center;
    justify-content: center;
    border: 0.25rem solid ${Colors.surfaceForegroundText};
    border-radius: 999rem;
    background-color: ${Colors.surfaceForeground};
  `};
`;

const InnerCircle = styled.div`
  ${() => css`
    display: flex;
    border-width: 5rem;
    border: 5rem;
    width: 14.25rem;
    height: 14.25rem;
    align-items: center;
    justify-content: center;
    border: 0.2rem solid ${Colors.surfaceForegroundText};
    border-radius: 999rem;
  `};
`;

const NotFoundNum = styled.h1`
  font-size: 3rem;
  font-weight: 300;
  color: ${Colors.surfaceForegroundText};
`;

const NotFoundText = styled.h1`
  font-size: 1.3rem;
  font-weight: 300;
  padding: 1rem;
  color: ${Colors.surfaceForegroundText};
`;

export default NotFound;