import { expTile0, expTile1, expTile2, expTile3, expTile4, expTile5, expTile6, expTile7, expTile8 } from "../assets/images";
import { RoutePath } from "../navigation/Routing";
import { ExperienceId, IExperienceCard } from "../types";

export const homeContent = {
  aboutMeTitle: "About",
  aboutMeContent: [
    "Hi there,",
    "I helped put 7+ mobile and web apps on the app stores and internet over the last 5 years as a software developer.",
    "I am a computer engineer by trade, which means that I was coding, drawing circuits, and reading obscure documentation in college.",
    "...react native, react, and iOS native apps below.",
  ],
  experienceTitle: "Experience",
  connectTitle: "Connect",
  connect_line1_0: "Please reach me on",
  connect_line1_1: "LinkedIn",
  connect_line1_2: "or",
  connect_line1_3: "X",
  connect_line2:
    "...all social media links below are live but reponse time varies.",
};

export const experiences: IExperienceCard[] = [
  {
    href: `${RoutePath.Experience}?experienceId=${ExperienceId.HboMax}`,
    image: expTile0,
    linkTitle: "Web and Mobile",
  },
  {
    href: `${RoutePath.Experience}?experienceId=${ExperienceId.Isbx}`,
    image: expTile1,
    linkTitle: "Web and Mobile",
  },
  {
    href: `${RoutePath.Experience}?experienceId=${ExperienceId.Intel}`,
    image: expTile2,
    linkTitle: "Enterprise",
  },
  {
    href: `${RoutePath.Experience}?experienceId=${ExperienceId.CalPoly}`,
    image: expTile3,
    linkTitle: "Embedded",
  },
  {
    href: `${RoutePath.Experience}?experienceId=${ExperienceId.IosBdayPal}`,
    image: expTile4,
    linkTitle: "Mobile",
  },
  {
    href: `${RoutePath.Experience}?experienceId=${ExperienceId.WebStyledResume}`,
    image: expTile5,
    linkTitle: "Web: Styled Resume",
  },
  {
    href: `${RoutePath.Experience}?experienceId=${ExperienceId.FpgaDigitImageRecognition}`,
    image: expTile6,
    linkTitle: "Embedded",
  },
  {
    href: `${RoutePath.Experience}?experienceId=${ExperienceId.UcdInfinityMirror}`,
    image: expTile7,
    linkTitle: "Embedded",
  },
  {
    href: `${RoutePath.Experience}?experienceId=${ExperienceId.CitrusHighAltitudeBalloon}`,
    image: expTile8,
    linkTitle: "Embedded",
  },
];

export const aboutMeContent = [
  "Hi there,",
  "I helped put 7+ mobile and web apps on the app stores and internet over the last 5 years as a software developer.",
  "I am a computer engineer by trade, which means that I was coding, drawing circuits, and reading obscure documentation in college.",
  "...react native, react, and iOS native apps below.",
];