import { Route, Routes } from "react-router-dom";
import { useAnalyticsPageViews } from "../analytics/analytics.hooks";
import { RoutePath } from "../navigation/Routing";
import Disclaimer from "../pages/Disclaimer";
import Experience from "../pages/Experience";
import Home from "../pages/Home";
import NotFound from "../pages/NotFound";
import PrivacyPolicy from "../pages/PrivacyPolicy";

const Router = () => {
  useAnalyticsPageViews();
  
  const pages = [
    { path: RoutePath.Home, component: <Home /> },
    { path: RoutePath.Disclaimer, component: <Disclaimer /> },
    { path: `${RoutePath.Experience}`, component: <Experience /> },
    { path: RoutePath.PrivacyPolicy, component: <PrivacyPolicy /> },
    { path: '*', component: <NotFound />}
  ];
  const routes = pages.map(({ path, component }) => (
    <Route path={path} element={component} key={`route-key-for-path-${path}`}></Route>
  ));

  return <Routes>{routes}</Routes>;
};

export default Router;
